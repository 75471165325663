import { t } from "i18next";
import * as yup from "yup";

export const locationSchema = yup.object().shape({
  locationName: yup.string(),
  street: yup.string().required(() => `${t("pos.location.locationForm.street")}`),
  city: yup.string().required(() =>`${t("pos.location.locationForm.city")}`),
  country: yup.string().required(() => `${t("pos.location.locationForm.country")}`),
  houseNumber: yup.string().required(() => `${t("pos.location.locationForm.house")}`),
  postalCode: yup.string().required(() => `${t("pos.location.locationForm.post")}`),
  addition: yup.string(),
});

