import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import { useFormContext } from "react-hook-form";

interface InputProps {
  label: string;
  name: string;
  id: string;
  type: string;
  placeholder?: string;
  className?: string;
  error?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ label, name, id, type, placeholder, className, error }, ref) => {
    const {
      register,
      formState: { errors },
    } = useFormContext();

    return (
      <div className={`w-full ${className}`}>
        <div className="relative">
          <input
            id={id}
            type={type}
            {...register(name)}
            placeholder=" "
            ref={ref}
            className="p-2 peer block w-full text-gray-700 bg-transparent border border-gray-300 rounded-md shadow-sm placeholder-transparent focus:outline-none focus:border-gray-900 focus:ring-0"
          />
          <label
            htmlFor={id}
            className="absolute left-3 -top-2.5 bg-white text-gray-500 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-2.5 peer-focus:text-gray-600 peer-focus:text-sm pointer-events-none"
          >
            {label}
          </label>
          {placeholder && (
            <span className="absolute left-3 top-2 transition-all text-gray-400 text-sm pointer-events-none peer-focus:opacity-0">
              {placeholder}
            </span>
          )}
        </div>
        <ErrorMessage
          name={name}
          render={({ message }) => (
            <div className="text-xs text-red-500 text-right">{message}</div>
          )}
        />
      </div>
    );
  }
);

Input.displayName = "Input";

export default Input;
