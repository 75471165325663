import React, { useState } from "react";
import Select, { components } from "react-select";
import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import cx from "clsx";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

interface SelectCountryProps {
  onCountryChange: (country: string) => void;
}

const SelectCountry: React.FC<SelectCountryProps> = ({ onCountryChange }) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const CountryOptions = [
    { value: "Netherlands", label: `${t("pos.location.country.nl")}` },
    { value: "Belgium", label: `${t("pos.location.country.bl")}` },
    { value: "France", label: `${t("pos.location.country.fr")}` }
    //{ value: "Germany", label: `${t("pos.location.country.de")}` },
  ];
  return (
    <div data-testid={"country"} className="">
      <Controller
        name="country"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            data-testid={"country"}
            options={CountryOptions}
            value={CountryOptions.find((option) => option.value === value)}
            onChange={(selectedOption) => {
              const selectedValue = selectedOption?.value;
              onChange(selectedValue);
              onCountryChange(selectedValue || "");
            }}
            isSearchable
            isLoading={isLoading}
            placeholder={t("placeholders.country")}
            noOptionsMessage={() => t("placeholders.noCountry")}
            loadingMessage={() => t("placeholders.loading")}
            styles={{
              control: (base, state) => ({
                ...base,
                backgroundColor: "white",
                boxShadow: "red",
                borderColor: state.isFocused ? "black" : "#d2d5db",
                ":hover": {
                  borderColor: state.isFocused ? "black" : "#d2d5db",
                },
                textAlign: "left",
                fontFamily: "inherit",
                fontWeight: "inherit",
                color: "inherit",
              }),
              placeholder: (base) => ({
                ...base,
                color: "#9ca3ae",
                fontFamily: "inherit",
                fontWeight: "inherit",
                fontSize: "0.91em",
              }),
              dropdownIndicator: (base, state) => ({
                ...base,
                color: state.isFocused ? "blue" : "gray",
                "&:hover": {
                  color: state.isFocused ? "blue" : "gray",
                },
              }),
              indicatorSeparator: () => ({
                display: "none",
              }),
              option: (base, state) => ({
                ...base,
                cursor: "pointer",
                padding: "6px 10px",
                backgroundColor: state.isFocused ? "#e1e2e3" : "white",
                color: "#4A5568",
                ":active": {
                  backgroundColor: "#CBD5E0",
                },
              }),
              indicatorsContainer: (base) => ({
                ...base,
                display: "flex",
                alignItems: "center",
                paddingRight: "2px",
                "& > div": {
                  marginRight: "8px",
                  color: "grey",
                },
              }),
              menuList: (provided) => ({
                ...provided,
                padding: "5px",
                maxHeight: "200px",
                overflowY: "auto",
                "::-webkit-scrollbar": {
                  width: "5px",
                },
                "::-webkit-scrollbar-track": {
                  backgroundColor: "#f5f5f5",
                },
                "::-webkit-scrollbar-thumb": {
                  backgroundColor: "#ccc",
                  borderRadius: "5px",
                },
              }),
            }}
            components={{
              IndicatorSeparator: null,
              Option,
              SingleValue,
            }}
          />
        )}
      />

      <div className="empty:my-2">
        <ErrorMessage
          name={"country"}
          render={({ message }) => (
            <div className="text-xs text-red-500 text-right">{message}</div>
          )}
        />
      </div>
    </div>
  );
};

export default SelectCountry;

const Option = (rest: any) => {
  return (
    <components.Option
      {...rest}
      className={cx([
        "block w-full text-left px-4 py-2 text-sm text-gray-700 focus:outline-none",
        rest.isFocused && "bg-gray-100 text-gray-900",
        rest.isSelected && "bg-gray-200 text-gray-900",
      ])}>
      {rest?.data?.label}
    </components.Option>
  );
};

const SingleValue = (rest: any) => (
  <>
    <components.SingleValue {...rest}>
      <div>{rest?.data?.label}</div>
    </components.SingleValue>
  </>
);
