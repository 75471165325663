import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import useCustomNavigate from "../../../components/pos/hooks/useCustomNavigate";
import { PosContext } from "../../../context/PosContext";
import { useDetermineOrderType } from "../../../components/pos/hooks/useDetermineOrderType";
import toast from "react-hot-toast";
import Modal from "../../../components/pos/ui/Modal";
import { formatPrice } from "../../../components/pos/utilities/formatPrice";

const SoftPosConfirmation = () => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const { orders, addNewOrder, softPosCreationData, updateOrderType } =
    useContext(PosContext);
  const orderType = useDetermineOrderType();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleRemoveClick = () => {
    setIsModalOpen(true);
  };

  const handleConfirmRemove = () => {
    // removeTerminal(orderId);
    setIsModalOpen(false);
  };

  // const handleAddNewOrder = () => {
  //   addNewOrder();
  //   navigate("/pos/terminal");
  // };

  // const handleFinish = () => {
  //   const isDeliveryAddressComplete =
  //     deliveryAddress &&
  //     deliveryAddress?.street?.length > 0 &&
  //     deliveryAddress?.houseNumber?.length > 0 &&
  //     deliveryAddress?.postalCode?.length > 0 &&
  //     deliveryAddress?.city?.length > 0 &&
  //     deliveryAddress?.country?.length > 0;

  //   if (isDeliveryAddressComplete) {
  //     updateSteps({ review: true });
  //     updateOrderType(orderType);
  //     navigate("/onboarding");
  //   } else {
  //     toast.error(`${t("pos.review.toast.deliveryAddress")}`);
  //   }
  // };

  const handleFinish = () => {
    if (softPosCreationData.location && softPosCreationData?.location) {
      updateOrderType(orderType);
      navigate(`/onboarding`);
    } else {
      toast.error(
        t(
          `${t("errors.toastError.migrate.title")} : ${
            !softPosCreationData.location
              ? t("errors.toastError.migrate.location")
              : ""
          }`
        )
      );
    }
  };

  if (!orders || orders.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center my-10">
        <div className="text-lg text-gray-700 mb-4">
          {t("pos.review.noOrder")}
        </div>
        <button
          onClick={() => navigate(`/pos/terminal`)}
          className="bg-primary-500 hover:bg-primary-700 text-white font-bold py-2 px-4 rounded"
        >
          {t("pos.buttons.goBack")}{" "}
        </button>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 pt-4 bg-white w-full md:max-w-3xl lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl">
      <h1 className="text-2xl font-bold mb-2 text-left">
        {t("terminals.softpos.confirmation.title")}
      </h1>
      <p className="text-gray-600 text-center sm:text-start font-semibold">
        {t("terminals.softpos.confirmation.p1")}
      </p>
      <div className="border-b my-4">
        <>
          <div className="flex flex-col md:grid md:grid-cols-12 px-4 pt-4 ">
            <div className="md:col-span-4">
              <div className="flex items-center justify-between mb-1">
                <h2 className="text-xl font-bold text-gray-800">
                  {softPosCreationData.terminalName}
                </h2>
              </div>
              <div className="text-md">{softPosCreationData.emailAddress}</div>
              <div className="text-md">
                <div className="flex">
                  <span className="mr-2">Price: </span>
                  <span>
                    1,7% {t("terminals.softpos.confirmation.priceValue")}
                  </span>
                </div>
              </div>
              <span className="text-md text-gray-700"></span>
              {t("pos.review.location")}{" "}
              {softPosCreationData.location &&
                softPosCreationData.location.locationName}
            </div>

            <div className="md:col-span-1 font-semibold mt-1 text-gray-800">
              {/* €{formatPrice(29)} */}
            </div>

            <div className="md:col-span-3 flex justify-end md:justify-center items-center">
              {isModalOpen && (
                <Modal
                  onClose={() => setIsModalOpen(false)}
                  title={`${t("pos.accessories.delete.remove")}`}
                >
                  <p className="text-lg text-gray-800 mb-4"></p>

                  <div className="flex mr-2 mb-2 justify-end space-x-3">
                    <button
                      onClick={() => setIsModalOpen(false)}
                      className="bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-900 font-semibold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-gray-300"
                    >
                      {t("pos.accessories.delete.cancelBtn")}
                    </button>
                    <button
                      onClick={handleConfirmRemove}
                      className="bg-red-200 hover:bg-red-300 text-red-800 hover:text-red-900 font-semibold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-red-300"
                    >
                      {t("pos.accessories.delete.removeBtn")}
                    </button>
                  </div>
                </Modal>
              )}
            </div>

            <div className="md:col-span-3  flex justify-start items-center">
              <span className="text-md p-1 text-gray-700">
                {/* €{formatPrice(recurringFee * (product?.quantity || 1))}/
                {t("pos.review.month")} */}
              </span>
            </div>
            <div className="md:col-span-1  flex justify-start items-center text-lg font-semibold text-gray-700 ">
              {/* {subscription?.Type.startsWith("lease")
                ? ""
                : `€${formatPrice(totalBuyFee)}`} */}
            </div>

            {/* <div className="hidden md:col-span-1 sm:flex justify-end items-center">
              <button
                onClick={handleRemoveClick}
                className="col-span-1 hover:text-red-500 active:text-red-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                  />
                </svg>
              </button>
            </div> */}
          </div>
          <div className="flex flex-col md:grid md:grid-cols-12 px-4 pb-4 ">
            <div className="text-lg font-semibold text-gray-700 col-span-3">
              {t("pos.review.fee")}
            </div>
            <div className=" text-gray-700 justify-start flex col-span-1 ">
              {/* €{formatPrice(29)} */}
            </div>
            <div className="col-span-6"></div>
            <div className="text-lg font-semibold text-gray-700 col-span-1 flex justify-start">
              €{formatPrice(29)}
            </div>
            {/* <div className="sm:hidden  flex justify-end items-center">
              <button
                onClick={handleRemoveClick}
                className="col-span-1 hover:text-red-500 active:text-red-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                  />
                </svg>
              </button>
            </div> */}
          </div>
        </>
      </div>
      <div className="mt-8 mb-4">
        <p>{t("terminals.softpos.confirmation.info")}</p>
      </div>
      <div className="flex flex-col p-4 md:flex-row justify-between mt-5">
        {/* <div className="mt-8 mb-10 sm:mb-0 sm:w-2/3">
          <h3 className="text-xl font-semibold mb-3">
            {t("pos.review.delivery")}
          </h3>
          <DeliveryLocation />
        </div> */}{" "}
      </div>
      <div className="flex justify-end space-x-4 mt-8">
        {/* <button
          onClick={handleAddNewOrder}
          className="
          text-primary-500 font-semibold bg-black hover:bg-primary-500 hover:text-black
           rounded-md px-4 py-2 transition-colors duration-200 
          "
        >
          {t("pos.buttons.review.terminal")}
        </button> */}
        <button
          onClick={() => handleFinish()}
          className="px-4 py-2 bg-primary-500 text-black font-bold rounded-md hover:bg-primary-700"
        >
          {t("terminals.softpos.buttons.name")}
        </button>
      </div>
    </div>
  );
};

export default SoftPosConfirmation;
