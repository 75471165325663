import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import useCustomNavigate from "../../../components/pos/hooks/useCustomNavigate";
import { PosContext } from "../../../context/PosContext";
import { useLocation } from "react-router-dom";
import { LocationType } from "../../../interfaces/IPos";
import MapView from "../../../components/pos/ui/MapView";
import LocationModal from "../../../components/pos/ui/LocationModal";
import AddLocationForm from "../../../components/pos/ui/AddLocationForm";

const SoftPosLocation = () => {
  const [loading, setLoading] = useState(true);
  const { pathname } = useLocation();
  const { updateSteps, softPosCreationData, updateSoftPosCreationData } =
    useContext(PosContext);
  const { t } = useTranslation();

  const initialSelectedLocationId = softPosCreationData.location
    ? softPosCreationData.location.id
    : null;

  const [selectedLocationId, setSelectedLocationId] = useState<number | null>(
    initialSelectedLocationId
  );

  const [locations, setLocations] = useState<LocationType[]>(
    softPosCreationData.location ? [softPosCreationData.location] : []
  );

  // useEffect(() => {
  //   const physicalStores = stores
  //     .filter((store) => store.type === "Physical")
  //     .map((store) => ({
  //       id: store.id,
  //       locationName: store.name,
  //       street: store.address.street || "",
  //       houseNumber: store.address.houseNumber || "",
  //       postalCode: store.address.postalCode || "",
  //       city: store.address.city || "",
  //       country: store.address.country || "",
  //       isSelected: false,
  //     }));

  //   setLocations((prevLocations: any) => {
  //     const newLocations = physicalStores.filter(
  //       (newStore) =>
  //         !prevLocations.some((prevLoc: any) => prevLoc.id === newStore.id)
  //     );
  //     return [...prevLocations, ...newLocations];
  //   });
  // }, [stores]);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const navigate = useCustomNavigate();

  const handleLocationSelect = (locationId: number) => {
    setSelectedLocationId(locationId);
  };

  const handleAddLocation = (newLocation: LocationType) => {
    const newLocationWithId = {
      ...newLocation,
      id: Math.random(),
      isSelected: true,
    };
    setLocations((prevLocations) => [...prevLocations, newLocationWithId]);
    setSelectedLocationId(newLocationWithId.id);
    updateSoftPosCreationData({ location: newLocationWithId });
    setIsModalOpen(false);
  };

  const handleDeleteLocation = (locationId: number) => {
    setLocations((prevLocations) => {
      const updatedLocations = prevLocations.filter(
        (location) => location.id !== locationId
      );
      return updatedLocations;
    });
  };

  const handleContinue = () => {
    if (!selectedLocationId) {
      toast.error(`${t("errors.toastError.selectLocation")}`);
      return;
    }

    const selectedLocation = locations.find(
      (loc) => loc.id === selectedLocationId
    );
    if (selectedLocation) {
      updateSoftPosCreationData({ location: selectedLocation });
      updateSteps({ softposlocation: true });
      navigate(`/pos/taptopay/configuration`);
    } else {
      toast.error(`${t("errors.toastError.noLocation")}`);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 pt-4 w-full md:max-w-3xl lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl">
      <h1 className="text-2xl  text-black font-bold mb-2 mt-4 text-left">
        {t("terminals.softpos.location.title")}
      </h1>
      <p className="text-lg text-primary-600 font-semibold mb-8">
        {t("terminals.softpos.location.p1")}
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
        <div
          className="flex flex-col w-64 ml-14 sm:ml-0 sm:w-full items-center justify-center p-6 border-2 border-dashed border-gray-300 rounded-xl shadow-sm hover:border-gray-400 cursor-pointer hover:bg-gray-50 transition duration-300"
          onClick={() => setIsModalOpen(true)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-12 h-12 text-gray-400"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
          <span className="text-gray-500 font-semibold mt-2">
            {t("pos.location.addLocation")}
          </span>
        </div>
        {locations.map((location) => (
          <div
            key={location.id}
            className={`flex flex-col w-64 sm:w-full justify-between ml-14 sm:ml-0 p-2 border border-gray-200 rounded-xl shadow transition duration-300 transform hover:shadow-lg ${
              selectedLocationId === location.id
                ? "ring ring-primary-500 bg-primary-100"
                : "bg-white"
            }`}
          >
            <MapView
              street={location.street}
              houseNumber={location.houseNumber}
              addition={location.addition}
              postalCode={location.postalCode}
              city={location.city}
              country={location.country}
              className="w-full mb-4 border flex justify-center"
              imgClassName="rounded-lg"
              zoom={17}
            />
            <div className="space-y-1 mb-2 px-3 truncate">
              <h3 className="text-xl font-semibold text-gray-800">
                {location.locationName}
              </h3>
              <p className="text-sm text-gray-600">
                {location.street}, {location.houseNumber} {location.addition}
              </p>
              <p className="text-sm text-gray-600">
                {location.postalCode}, {location.city}, {location.country}
              </p>
            </div>
            <div className="flex justify-between items-center">
              <button
                onClick={() => handleDeleteLocation(location.id)}
                className="rounded-full text-red-500 hover:text-red-700 transition duration-300"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                  />
                </svg>
              </button>
              <button
                onClick={() => handleLocationSelect(location.id)}
                className="bg-primary-500 text-black font-semibold py-2 px-4 rounded-lg shadow hover:shadow-lg hover:bg-primary-600 focus:outline-none focus:ring focus:ring-primary-600 transition duration-300"
              >
                {t("pos.buttons.select.name")}
              </button>
            </div>
          </div>
        ))}
      </div>
      <LocationModal
        onClose={() => setIsModalOpen(false)}
        title={`${t("terminals.softpos.location.addLocation")}`}
        showCloseIcon={true}
        isOpen={isModalOpen}
      >
        <AddLocationForm onAddLocation={handleAddLocation} />
      </LocationModal>
      <div className="flex justify-end mt-6">
        <button
          onClick={handleContinue}
          className="bg-primary-500 text-black font-bold py-2 px-4 rounded hover:bg-primary-600 focus:outline-none focus:ring focus:ring-primary-300 transition duration-300"
        >
          {t("pos.buttons.continue.name")}
        </button>
      </div>
    </div>
  );
};

export default SoftPosLocation;
